import { AccountInfo, Configuration, EndSessionRequest, EventType, LogLevel, PopupRequest, PublicClientApplication, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { getSessionStorage, removeSessionStorage, setSessionStorage } from "../RAFComponents/helpers/AppHelper";
import { StorageKey } from "../RAFComponents/helpers/Constants";
import { getQueryString, getSubDomain, hasSSO, isNotNullAndUndefined, IsNullOrWhiteSpace } from "../RAFComponents/helpers/utils";
import { TenantRow } from "../RAFComponents/models/Common/TenantRow";
import { UserInfoRow } from "../RAFComponents/models/Common/UserInfoRow";
//import { UIManager } from "./UIManager";
//import { SsoSilentRequest } from "@azure/msal-browser/dist/src/request/SsoSilentRequest";
//import * as R from "ramda";

export const AzureAdB2C = {
    "Instance": "https://skytrustsecureid.b2clogin.com",
    "Domain": "skytrustsecureid.onmicrosoft.com",
    "AuthorityDomain": "skytrustsecureid.b2clogin.com",
    "ClientId": "bab47543-b76a-4f67-8ef4-6c7b07b4ddd9",

    "DefaultSignUpPolicyId": "B2C_1_signup_email",
    "MicrosoftSignUpSignInPolicyId": "B2C_1A_SIGNUP_SIGNIN_MS",

    // //"DefaultSignInPolicyId": "B2C_1_default_signin",
    // "DefaultSignInPolicyId": "B2C_1_e565cc9cc-b5c6-4f02-9980-dc98d7d87ead",
    // "DefaultSignInMFAPolicyId": "B2C_1_te03103d4-218f-409e-b812-91cf054bd3c8",

    "DefaultSignInPolicyId": "B2C_1A_SIGNUP_SIGNIN_MS",
    "DefaultSignInMFAPolicyId": "B2C_1A_SIGNUP_SIGNIN_MS",


    "AcquireTokenScope": "https://skytrustsecureid.onmicrosoft.com/532cfc83-700d-40aa-a4f7-8b322fdd353d/access_as_interactive_user"
};

const redirectUri = '/authsuccess';// IsNullOrWhiteSpace(process.env.REACT_APP_CENTRAL_AUTHSUCCESSPAGE) ? "https://beta.secure.skytrust.co/authsuccess" : process.env.REACT_APP_CENTRAL_AUTHSUCCESSPAGE;
export const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: AzureAdB2C.ClientId,
        authority: `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignInPolicyId}`,// Choose SUSI as your default authority.
        knownAuthorities: [AzureAdB2C.AuthorityDomain],
        redirectUri: redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        //navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        //console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        //console.warn(message);
                        return;
                }
            }
        }
    }
};

export enum SkytrustLoginEventType {
    BU_START = "msal:buStart",
    BU_SUCCESS = "msal:buSuccess",
    BU_FAILURE = "msal:buFailure",
    BU_DISABLED = "msal:buDisabled",
    ACCESSTOKEN_START = "msal:accessTokenStart",
    ACCESSTOKEN_SUCCESS = "msal:accessTokenSuccess",
    ACCESSTOKEN_FAILURE = "msal:accessTokenFailure",
    ACTIVATE_SUCCESS = "msal:activateSuccess",
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export let loginRequest: RedirectRequest = {
    // scopes: [AzureAdB2C.AcquireTokenScope,
    //     //...protectedResources.apiTodoList.scopes.write
    // ],
    scopes: [AzureAdB2C.AcquireTokenScope],
    //prompt: 'select_account'
};

export class RAFAuthModule extends PublicClientApplication {
    private signUpRedirectRequest: RedirectRequest;
    //private loginRequest: PopupRequest;
    public acquireTokenSilentRequest: SilentRequest;

    // constructor(configuration: Configuration) {
    //     super(configuration);

    //     // loginRequest = {
    //     //     scopes: []
    //     // };

    //     //const redirectUri = "/";
    //     //const redirectUri = "https://localhost:3000/authsuccess";
    //     const redirectUri = IsNullOrWhiteSpace(process.env.REACT_APP_CENTRAL_AUTHSUCCESSPAGE) ? "https://beta.secure.skytrust.co/authsuccess" : process.env.REACT_APP_CENTRAL_AUTHSUCCESSPAGE;

    //     loginRedirectRequest = {
    //         ...loginRequest,
    //         //redirectStartPage: window.location.href,
    //         redirectUri: redirectUri,
    //         //domainHint: window.location.hostname,
    //         //extraQueryParameters: { login_hint: "jagan@radamtech.com" },
    //         //extraQueryParameters: { local_login: "false" },
    //     };

    //     // loginRequest = {
    //     //     ...loginRequest,
    //     //     redirectUri: redirectUri,
    //     // };

    //     this.signUpRedirectRequest = {
    //         ...loginRequest,
    //         //redirectStartPage: window.location.href,
    //         redirectStartPage: "/",
    //         redirectUri: redirectUri,
    //         //loginHint: "satheesh@radamtech.com",
    //         //domainHint: window.location.origin,
    //         //extraQueryParameters: { login_hint: "satheesh@radamtech.com" },
    //     };

    //     this.acquireTokenSilentRequest = {
    //         //scopes: [AzureAdB2C.AcquireTokenScope]
    //         scopes: appConfig.b2cAcquireTokenScope
    //     };


    //     this.setAuthority();
    // }

    private setAuthority(): void {
        loginRequest.state = 'rafsubdomain' + window.location.host.split('.')[0];
        this.signUpRedirectRequest.state = 'rafsubdomain' + window.location.host.split('.')[0];

        let policyIdSuffix = null;
        let subDomain = getSubDomain();
        const domainHint = getQueryString("domainHint");
        if (!IsNullOrWhiteSpace(domainHint)) {
            subDomain = domainHint;
            this.signUpRedirectRequest.redirectStartPage = '/?domainHint=' + domainHint;
        }
        if (IsNullOrWhiteSpace(subDomain)) {
            policyIdSuffix = null;

            let authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignInPolicyId}`;
            loginRequest.authority = authority;
            this.acquireTokenSilentRequest.authority = authority;
        }
        else {
            hasSSO(subDomain).then((hasSSO) => {
                if (hasSSO) {
                    policyIdSuffix = subDomain;
                }
                else {
                    policyIdSuffix = null;
                }

                let authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignInPolicyId}`;
                if (!IsNullOrWhiteSpace(policyIdSuffix)) {
                    authority = `${AzureAdB2C.Instance}/${AzureAdB2C.Domain}/${AzureAdB2C.DefaultSignInPolicyId.replace("_default_", `_${policyIdSuffix}_`)}`;
                }

                loginRequest.authority = authority;
                this.acquireTokenSilentRequest.authority = authority;

            }).catch((error) => error);
        }
    }

    getUserName(): string | null {
        const account = this.getActiveAccount();
        if (isNotNullAndUndefined(account)) {
            return account.name;
        }
        return null;
    }

    getRootBusinessUnitId(): string | null {
        const account = this.getActiveAccount();
        if (isNotNullAndUndefined(account)) {
            const idTokenClaims = account.idTokenClaims;
            if (isNotNullAndUndefined(idTokenClaims)) {
                return idTokenClaims["businessUnitUID"] as string;
            }
        }
        return null;
    }

    // get isAuthenticated(): boolean {
    //     const account = this.getActiveAccount();
    //     if (isNotNullAndUndefined(account)) {
    //         return true;
    //     }
    //     return false;
    // }

    logInAD(loginHint?: string): void {

        if (isNotNullAndUndefined(loginHint)) {
            loginRequest.loginHint = loginHint;
        }

        this.loginRedirect(loginRequest);
    }

    logOutAD(): void {
        const account: AccountInfo = this.getActiveAccount();
        if (account) {
            const logOutRequest: EndSessionRequest = {
                account,
                postLogoutRedirectUri: "/"
            };
            this.logoutRedirect(logOutRequest);
        }
    }

    getADToken1() {
        const account = this.getActiveAccount();
        if (!account) {
            //throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            console.log("No active account! Verify a user has been signed in and setActiveAccount has been called.");
            //window.location.href = "/";
        }

        //if (IsNullOrWhiteSpace(this._accessToken)) {


        return new Promise<string>((resolve, reject) => {

            return this.acquireTokenSilent({
                ...this.acquireTokenSilentRequest,
                account: account
            }).then((response) => {
                this._accessToken = response.accessToken;
                resolve(response.accessToken);
            }).catch((error) => {
                console.log('getADToken 1', { error });
                // debugger;

                reject(error);
                // if (error instanceof InteractionRequiredAuthError || error.errorCode === "interaction_required") {
                //     // fallback to interaction when silent call fails
                //     return this.acquireTokenRedirect({
                //         ...this.acquireTokenSilentRequest,
                //         account: account
                //     });
                // }
                // else if (error.errorCode === "consent_required" || error.errorCode === "login_required" || error.errorCode === "invalid_grant" || error.errorCode === "monitor_window_timeout") {
                //     //console.log('1.1', { error });

                //     // if (msalSubject.eventType === 'msal:acquireTokenFailure') {
                //     //     //If both tokens are wrong or deleted, acquireTokenFailure gets emited
                //     //     // Do something
                //     //   }



                //     // // fallback to interaction when silent call fails
                //     // return this.acquireTokenRedirect({
                //     //     ...this.acquireTokenSilentRequest,
                //     //     account: account
                //     // });

                //     return this.loginRedirect({
                //         ...this.loginRedirectRequest,
                //         account: account
                //     });

                // }
            });

        });

        //}
        //else {
        //    return new Promise<string>((resolve, reject) => {
        //        resolve(this._accessToken);
        //    });
        //}

    }

    async getADToken() {
        const account = this.getActiveAccount();
        const response = await this.acquireTokenSilent({
            scopes: [AzureAdB2C.AcquireTokenScope],
            //scopes: appConfig.b2cAcquireTokenScope,
            account: account
        });
        this._accessToken = response.accessToken;
        return response.accessToken;
    }

    get currentBusinessUnitId(): string {
        const recentClickedWorkspace = JSON.parse(getSessionStorage(StorageKey.CurrentPortal, true));

        if (isNotNullAndUndefined(recentClickedWorkspace) && isNotNullAndUndefined(recentClickedWorkspace.PortalName)) {
            return isNotNullAndUndefined(recentClickedWorkspace.UID) ? recentClickedWorkspace.UID : null;
        } else {
            return isNotNullAndUndefined(this.currentUser) ? this.currentUser.CurrentPortalUID : null;
        }
    }

    get currentBusinessUnitName(): string {
        const recentClickedWorkspace = JSON.parse(getSessionStorage(StorageKey.CurrentPortal, true));
        if (isNotNullAndUndefined(recentClickedWorkspace) && isNotNullAndUndefined(recentClickedWorkspace.PortalName)) {
            return isNotNullAndUndefined(recentClickedWorkspace.UID) ? recentClickedWorkspace.PortalName : null;
        } else {
            return isNotNullAndUndefined(this.currentUser) ? this.currentUser.CurrentPortal : null;
        }
    }

    // get currentBusinessUnitId(): string {
    //     return isNotNullAndUndefined(this.currentBusinessUnit) ? this.currentBusinessUnit.UID : null;
    // }

    // get currentBusinessUnitName(): string {
    //     return isNotNullAndUndefined(this.currentBusinessUnit) ? this.currentBusinessUnit.BusinessUnitName : null;
    // }

    get currentBusinessUnit(): { UID: string, BusinessUnitName: string; } {
        let retVal: { UID: string, BusinessUnitName: string; } = null;
        const objStoarge1 = getSessionStorage(StorageKey.currentBusinessUnit);
        if (!IsNullOrWhiteSpace(objStoarge1)) {
            retVal = JSON.parse(objStoarge1);
        }
        return retVal;
    }

    set currentBusinessUnit(value: { UID: string, BusinessUnitName: string; }) {
        isNotNullAndUndefined(value) ? setSessionStorage(StorageKey.currentBusinessUnit, false, JSON.stringify(value)) : removeSessionStorage(StorageKey.currentBusinessUnit, false);
    }

    get currentUserId(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.UserUID : null;
    }

    get currentUserName(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.DisplayName : null;
    }

    get currentTenantId(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.TenantUID : null;
    }

    get currentTenantName(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.TenantName : null;
    }

    get currentTenants(): TenantRow[] {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.Tenants : null;
    }

    get currentUserEmail(): string {
        return isNotNullAndUndefined(this.currentUser) ? this.currentUser.Email : null;
    }

    //get currentUser(): { UserUID: string, DisplayName: string, Email: string  } {
    get currentUser(): UserInfoRow {
        //let retVal: { UserUID: string, DisplayName: string, Email: string, TenantUID: string, Tenants: TenantRow[], TenantName: string } = null;
        let retVal: UserInfoRow = null;
        const objStoarge1 = getSessionStorage(StorageKey.currentUser);
        if (!IsNullOrWhiteSpace(objStoarge1)) {
            retVal = JSON.parse(objStoarge1);
        }
        return retVal;
    }

    //set currentUser(value: { UserUID: string, DisplayName: string, Email: string }) {
    set currentUser(value: UserInfoRow) {
        isNotNullAndUndefined(value) ? setSessionStorage(StorageKey.currentUser, false, JSON.stringify(value)) : removeSessionStorage(StorageKey.currentUser, false);
    }





    private _accessToken: string = null;

    get accessToken(): string {
        return this._accessToken;
    }

    set accessToken(objToken: string) {
        this._accessToken = objToken;
    }

    private _error: string = null;

    get error(): string {
        return this._error;
    }

    set error(eventType: string) {
        this._error = eventType;
    }

    private _currentEventType: EventType = null;

    get currentEventType(): EventType {
        return this._currentEventType;
    }

    set currentEventType(eventType: EventType) {
        this._currentEventType = eventType;
    }

    public onSkytrustLoginEventTypeChange: (val: SkytrustLoginEventType) => void;
}