import { InteractionRequiredAuthError, InteractionStatus, InteractionType, PopupRequest } from "@azure/msal-browser";
import { MsalAuthenticationResult, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { PropsWithChildren, Suspense, useEffect, useState } from "react";
import LoginSlider from "../components/shared/LoginSlider";
import SkyLoadingPanel from "../components/shared/SkyLoadingPanel";
import { getUserInfo } from "../helpers/SkyUtils";
import skyloading2 from "../images/skytrust-loading.png";
// import skyloading2 from "../images/ACLoadingIcon.svg";
import { loginRequest } from "../RAFAzure/AuthModule";
import { Logout } from "../RAFComponents/helpers/AppHelper";
import { IsNullOrWhiteSpace, isNotNullAndUndefined } from "../RAFComponents/helpers/utils";
import { UserInfoRow } from "../RAFComponents/models/Common/UserInfoRow";
import { CanImpersonateUser } from "../SkytrustModules/User/UserHelper";
import { StorageKey } from "../RAFComponents/helpers/Constants";
//import { initializeRAFDB } from "../WorkesioRepository";

export const LoginUserContext = React.createContext<{ userInfo: UserInfoRow; canImpersonateUser: boolean; }>({ userInfo: null, canImpersonateUser: false });
export const LoginUserUpdateContext = React.createContext<{ updateUserInfo: (currentUser: UserInfoRow) => void; }>({ updateUserInfo: null });


interface Props {
    children: React.ReactNode;
}

const RAFLoginUserContent = ({ children }: Props) => {
    const { instance, inProgress, logger } = useMsal();
    const [userInfo, setUserInfo] = useState<null | UserInfoRow>(null);
    const [canImpersonateUser, setCanImpersonateUser] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const clientLogo = `${window.location.origin}/Content/img/SkytrustSecureIDLogo.png`;


    const logoutSubmit = (event) => {
        event.preventDefault();
        // setSessionStorage(RMStorageKey.isAdmin, false, "false");
        // setSessionStorage(
        //     RMStorageKey.currentMenuCategory,
        //     false,
        //     MenuCategory.Default
        // );
        // instance.logoutRedirect();
        Logout();
    };

    useEffect(() => {
        // console.log('RAFLoginUserContent1', instance.getAllAccounts(), { instance });
        // console.log('RAFLoginUserContent2', { inProgress });
        // console.log('RAFLoginUserContent3', { userInfo });

        refresh();

    }, [inProgress, userInfo, instance]);

    const refresh = () => {
        if (!userInfo && inProgress === InteractionStatus.None) {
            getUserInfoRow();
        }
    };

    const getUserInfoRow = () => {
        setLoading(true);
        getUserInfo().then(userInfoResponse => {
            //const canImpersonateUser = await CanImpersonateUser();
            const userInfoRow: UserInfoRow = userInfoResponse as UserInfoRow;
            if (isNotNullAndUndefined(userInfoRow) && isNotNullAndUndefined(userInfoRow.UserUID)
                // && isNotNullAndUndefined(userInfoRow.Tenants) && userInfoRow.Tenants.length > 0
            ) {
                CanImpersonateUser().then(canImpersonateUser => {
                    // msalInstance.currentBusinessUnit = null;
                    // //msalInstance.currentUser = { UserUID: userInfoRow.UserUID, DisplayName: userInfoRow.DisplayName, Email: userInfoRow.Email };
                    instance["currentUser"] = userInfoRow;
                    let currentTenantRow = window.localStorage.getItem(StorageKey.currentTenant);
                    if (IsNullOrWhiteSpace(currentTenantRow)) {
                        if (!IsNullOrWhiteSpace(userInfoRow.TenantUID)) {
                            const objTenant = userInfoRow.Tenants.find(x => x.UID === userInfoRow.TenantUID);
                            if (isNotNullAndUndefined(objTenant)) {
                                window.localStorage.setItem(StorageKey.currentTenant, JSON.stringify(objTenant));
                            }
                            else {
                                window.localStorage.setItem(StorageKey.currentTenant, JSON.stringify(userInfoRow.Tenants[0]));
                            }
                        }
                        else {
                            window.localStorage.setItem(StorageKey.currentTenant, JSON.stringify(userInfoRow.Tenants[0]));
                        }
                    }

                    // initializeRAFDB().then(() => {
                    //     initializeRAFFB().then(() => {

                    //setLoginEventType(SkytrustLoginEventType.BU_SUCCESS);
                    setCanImpersonateUser(canImpersonateUser);
                    setUserInfo(userInfoRow);
                    setLoading(false);
                    //     });
                    // });
                }).catch((e) => {
                    setCanImpersonateUser(false);
                    setUserInfo(userInfoRow);
                    setLoading(false);

                });
            }
            else {
                if (typeof userInfoRow === "number" && userInfoRow === 403) {
                    // Handle 403 error here
                    console.log("getUserInfoRow api call end else userinfo is 403", userInfoRow);
                    window.localStorage.removeItem(StorageKey.currentTenant);
                    setCanImpersonateUser(false);
                    setUserInfo(null);
                    setLoading(false);
                }
                else if (typeof userInfoRow === "number" && userInfoRow === 400) {
                    // Handle 400 error here
                    console.log("getUserInfoRow api call end else userinfo is 400", userInfoRow);
                    setCanImpersonateUser(false);
                    setUserInfo(null);
                    setLoading(false);

                    instance.logoutRedirect();
                }
                else {
                    console.log("getUserInfoRow api call end else userinfo is null & not 403", userInfoRow);
                    setCanImpersonateUser(false);
                    setUserInfo(null);
                    setLoading(false);
                }
            }
        }).catch((e) => {
            if (e instanceof InteractionRequiredAuthError) {
                // instance.acquireTokenRedirect({
                //     scopes: [AzureAdB2C.AcquireTokenScope],
                //     account: instance.getActiveAccount() as AccountInfo
                // });

                localStorage.clear();
                sessionStorage.clear();
                //Logout();
                instance.logoutRedirect();
                //or go to main page
            }
            else if (e.errorCode === "monitor_window_timeout") {
                //instance.logoutRedirect();
                localStorage.clear();
                sessionStorage.clear();
                Logout();
            }
            else {
                setCanImpersonateUser(false);
                setUserInfo(null);
                setLoading(false);
            }
        });
    };

    const updateUserInfo = (currentUser: UserInfoRow) => {
        instance["currentUser"] = currentUser;
        getUserInfoRow();
    };

    return (
        <>
            {userInfo ? (
                <LoginUserUpdateContext.Provider
                    value={{
                        updateUserInfo: updateUserInfo
                    }}
                >
                    <LoginUserContext.Provider
                        value={{
                            userInfo: userInfo,
                            canImpersonateUser: canImpersonateUser
                        }}
                    >
                        {children}
                    </LoginUserContext.Provider>
                </LoginUserUpdateContext.Provider>
            ) :
                (

                    <div className="h-100">
                        <div className="h-100 content panel p-0">
                            <div className="h-100 d-flex justify-content-lg-end justify-content-center">
                                <div className="right-section d-none d-lg-block min-vh-100">
                                    <div className="h-100 position-relative">
                                        <Suspense fallback={<SkyLoadingPanel loadingText="Loading..." />}>
                                            <LoginSlider />
                                        </Suspense>
                                    </div>
                                </div>
                                <div className="left-section bg-white">
                                    <div className="loginContainer overflow-auto d-flex align-items-center flex-column">
                                        <div className="w-100 formContainer d-flex flex-column align-items-center justify-content-center">
                                            {loading === true ? (
                                                <div>
                                                    <div className="d-flex flex-column align-items-center" >
                                                        <img src={skyloading2} width="100px" alt="Loading" />
                                                        <span className="loadingText text-center">One moment<br />while we sign you in...</span>
                                                    </div>
                                                </div>
                                            )
                                                :
                                                (
                                                    <div className="text-center">
                                                        <img src={clientLogo} alt="Product Logo" className="clientlogoImage mb-3" />
                                                        <div className="d-flex justify-content-center align-items-center pb-2">
                                                            <h5>Looks like your login is disabled or you do not have access to this site</h5>
                                                        </div>
                                                        <div className="d-flex justify-content-center align-items-center pb-2">
                                                            <form
                                                                onSubmit={logoutSubmit}
                                                                className="d-flex justify-content-center align-items-center flex-column w-100 me-2">
                                                                <ButtonComponent type="submit" cssClass='e-flat e-primary primary-btn me-2 w-100'>Continue</ButtonComponent>
                                                            </form>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default function RAFLoginUserContextProvider({ children }) {
    return (
        <RAFLoginUserContent>
            {children}
        </RAFLoginUserContent>
    );
}
